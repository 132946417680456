.line {

  @include from-inverted-colors using ($color, $value) {
    &.line-#{$color} {
      &::after {
        background: $value;
      }
    }
  }
}
