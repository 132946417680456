.doc-section {
  &-header {
    padding-top: spacer(3);
    padding-bottom: spacer(2);
    border-bottom: 1px solid theme-color("accent");

    a {

      @extend .text-primary, .text-decoration-none;
    }
  }

  &-body {
    margin-top: spacer(3);
  }

  .doc-subsection {
    margin-bottom: spacer(4);

    &-header {
      margin-bottom: spacer(3);

      a {

        @extend .text-primary, .text-decoration-none;
      }
    }

    &-body {
      .sub-title {
        margin-top: spacer(3);

        a {

          @extend .text-primary, .text-decoration-none;
        }
      }
    }

    &-border {
      padding-top: spacer(2);
      padding-bottom: spacer(2);
      margin-bottom: spacer(2);
      border-bottom: 1px solid theme-color("neutral");
    }
  }

  .blockquote {
    padding: spacer(2) spacer(3);

    @extend .bg-light;
  }
}
