.card-detail {
  overflow: hidden;
  background-color: color("white");
  border: $card-detail-border-width solid $card-detail-border-color;
  border-radius: $card-detail-border-radius;

  &-image-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: $card-detail-img-height;
    padding: $card-detail-img-padding;

    // It assumes that the image is a logo, so it
    // should not have object-fit equal to cover
    img {
      max-width: 100%;
      max-height: 100%;
    }

    .badge {
      position: absolute;
      top: 4px;
      left: 4px;
    }
  }

  &-body {
    width: 100%;
    padding: $card-detail-body-padding;

    :last-child {
      margin-bottom: 0;
    }
  }

  .list-group-item {
    background-color: transparent;
    border-top: $card-detail-border-width solid $card-detail-border-color;
  }
}
