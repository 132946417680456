.page-news {
  figure {
    figcaption {
      color: $invert-page-news-figcaption-color;
    }
  }

  &-strip {
    .icon {
      color: $invert-page-news-strip-icon-color;
    }
  }
}
