.card-news {
  margin-bottom: spacer(4);
  background-color: color("white");
  border: 1px solid theme-color("accent");

  &-image {
    position: relative;
    height: $card-news-image-height;

    @include media($from: desktop) {
      height: $card-news-lg-image-height;
    }

    .badge {
      position: absolute;
      top: 4px;
      left: 4px;
    }

    &-center {
      width: $card-news-image-center-width;
      margin: spacer(4) auto;
    }
  }

  &-body-with-image-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: rem($card-news-body-with-image-bg-min-height);
    padding: spacer(4);

    h3 {
      margin-bottom: spacer(4);
      font-weight: $font-weight-bold;
    }
  }

  &:hover {
    color: theme-color();
    border: 1px solid theme-color();

    a {
      color: theme-color();
    }
  }

  &-body {
    width: 100%;
    padding: $card-news-padding;

    > small {
      display: block;
      margin: 0 0 $card-news-padding;
      overflow: hidden;
      opacity: $font-opacity;
    }

    > a {
      display: block;
      height: auto;
      margin: 0;
      overflow: hidden;
    }
  }

  &-footer {
    min-height: $card-news-footer-min-height;

    .btn {
      margin: 0 spacer(3) spacer(3);
    }
  }

  &.with-lead {
    .card-news-body {
      > a {
        height: auto;
        text-decoration: none;
      }

      h4,
      .card-news-title {
        display: block;
        margin: 0 0 $card-news-padding;
        overflow: hidden;
      }

      p {
        display: block;
        margin: 0 0 $card-news-padding;
        overflow: hidden;
      }
    }
  }
}
